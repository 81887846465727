import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/estilo.css';
//import App from './App';
//import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);


const Loading = () => (
  <div className="loading">
    <div className="loading-bar">
      <div className="loading-bar__progress"></div>
    </div>
  </div>
);



const App = lazy(() => import('./App'));

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </React.StrictMode>
);
